body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.buttonMain {
    border-radius: 10px;
    font-weight: 900;
    cursor: pointer;
}
@font-face {
  font-family: antraste;
  src: url(/static/media/antraste.278434de.ttf);
}

.App {
  height: 100%;
  position: relative;
  background-color: #ffbc22;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.walletConnectionContainer {
  border: solid blue;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
}
.walletConnectionContainer > button {
  margin-right: 20px;
}

.mainContentContainer {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.mainContentContainer > .header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  background-color: rgba(61, 61, 61, 0.8);
  width: 100%;
}
.mainContentContainer > .header > .imgContainer {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 70px;
}
.mainContentContainer > .header > .imgContainer > img {
  width: 100%;
  height: 100%;
  min-width: 70px;
}
.mainContentContainer > .header > .socialContainer {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.mainContentContainer > .header > .socialContainer > ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.mainContentContainer > .header > .socialContainer > ul > a {
  text-decoration: none;
}
.mainContentContainer > .header > .socialContainer > ul > a > li {
  padding: none;
  margin: 0px 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
}
.mainContentContainer
  > .header
  > .socialContainer
  > ul
  > a:first-child
  > li
  > img {
  height: 55px;
  width: 55px;
}
.mainContentContainer > .header > .socialContainer > ul > a > li > img {
  height: 100%;
  width: 100%;
}

.mainContentContainer > .cover {
  box-sizing: border-box;
  border: transparent;
  height: calc(100vw / 2);
  width: 100vw;
  max-width: 2200px;
  display: flex;
  justify-content: center;
}
.mainContentContainer > .cover > .coverImage {
  background-image: linear-gradient(to bottom, transparent 98%, #ffbc22),
    url(/static/media/cover.04fce807.jpg);
  background-repeat: no-repeat;
  background-size: 100%;

  height: 100%;
  width: 100%;
  border: transparent;
}
.mainContentContainer > .description {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mainContentContainer > .description > .main,
.mainContentContainer > .description > .sub {
  text-align: center;
  font-family: antraste;
  color: white;
  text-shadow: 3px 3px 5px rgb(97, 97, 97);
}

.mainContentContainer > .description > .main > .first {
  width: 100%;
  word-spacing: 10px;
  letter-spacing: 2px;
}
.mainContentContainer > .description > .main > .first > span,
.mainContentContainer > .description > .sub > h3 > span {
  font-size: calc(100% + 50%);
}
.mainContentContainer > .description > .main > .second {
  word-spacing: 1px;
}
.mainContentContainer > .mainContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.mainContentContainer > .mainContainer > .cardLeft {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  order: 1;
}
.mainContentContainer > .mainContainer > .cardRight {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  order: 3;
}
.mainContentContainer > .mainContainer > .cardLeft > ul,
.mainContentContainer > .mainContainer > .cardRight > ul {
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.mainContentContainer > .mainContainer > .cardLeft > ul > li,
.mainContentContainer > .mainContainer > .cardRight > ul > li {
  padding: 0;
  margin: 0;
  background-color: white;
  margin: 12px;
  box-shadow: 3px 3px 15px 0px rgb(97, 97, 97);
  height: 150px;
  padding: 3px;
}
.mainContentContainer > .mainContainer > .cardLeft > ul > li > img,
.mainContentContainer > .mainContainer > .cardRight > ul > li > img {
  width: auto;
  height: 100%;
  padding: 0;
  margin: 0;
}
.mainContentContainer > .mainContainer > .minter {
  order: 2;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mainContentContainer > .mainContainer > .minter > .counter,
.mainContentContainer > .mainContainer > .minter > .sub {
  text-align: center;
  font-family: antraste;
  color: white;
  text-shadow: 3px 3px 5px rgb(97, 97, 97);
}
.mainContentContainer > .mainContainer > .minter > .counter {
  margin-top: 20px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mainContentContainer > .mainContainer > .minter > .counter > h4 {
  min-width: 70px;
  font-size: 20px;
}
.mainContentContainer > .mainContainer > .minter > .sub > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding: 10px;
}
.mainContentContainer
  > .mainContainer
  > .minter
  > .sub
  > div
  > span:first-child {
  text-align: right;
  width: 30%;
  padding-right: 10px;
}
.mainContentContainer > .mainContainer > .minter > .minterContainer {
  position: relative;
  background-color: white;
  display: flex;
  position: relative;
  border-radius: 50px;
  box-shadow: 0px 2px 15px 0px rgb(97, 97, 97);
  margin-top: 20px;
  margin-bottom: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  color: rgb(102, 102, 102);
  padding: 20px;
}
.mainContentContainer > .mainContainer > .minter > .minterContainer > h2 {
  text-align: center;
}
.mainContentContainer > .mainContainer > .minter > .minterContainer > ul {
  padding: 0;
  margin: 0;
  padding: 10px;
  list-style-type: none;
}
.mainContentContainer > .mainContainer > .minter > .minterContainer > ul > li {
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: solid 1px #fbdbb6;
}
.mainContentContainer
  > .mainContainer
  > .minter
  > .minterContainer
  > ul
  > li
  > div {
  display: flex;
  flex-direction: column;
}
.mainContentContainer
  > .mainContainer
  > .minter
  > .minterContainer
  > ul
  > li
  > div
  > span {
  text-align: center;
  font-size: 0.8em;
  font-style: italic;
}
.mainContentContainer
  > .mainContainer
  > .minter
  > .minterContainer
  > ul
  > .amountControl
  > span {
  min-width: 20px;
  max-width: 20px;
  text-align: center;
}
.mainContentContainer > .mainContainer > .minter > .minterContainer > ul > hr {
  border: none;
  height: 1px;
  background-color: #fbdbb6;
}
.mainContentContainer
  > .mainContainer
  > .minter
  > .minterContainer
  > ul
  > li
  > label {
  width: 100%;
  max-width: 200px;
  text-align: left;
}
.mainContentContainer
  > .mainContainer
  > .minter
  > .minterContainer
  > ul
  > li
  > span {
  font-weight: bold;
}
.mainContentContainer
  > .mainContainer
  > .minter
  > .minterContainer
  > .mintButton {
  padding: 20px 0px;
  text-align: center;
}
.mainContentContainer
  > .mainContainer
  > .minter
  > .minterContainer
  > .connectModal {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  padding: 0px;
  color: #ffbc22;
}
.mainContentContainer > .mainContainer > .minter > .minterContainer > .soon {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  padding: 0px;
  font-family: antraste;
  color: #ffbc22;
  text-shadow: 3px 3px 3px rgb(97, 97, 97, 0.2);
}
.disconnectButton {
  position: absolute;
  bottom: 10px;
  left: calc(100% / 5);
}
.connected {
  display: none;
}
.disconnected {
  display: flex;
}
.mainContentContainer > .block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ccc;
  z-index: 1;
  opacity: 0.5;
}
.blockOn {
  display: none;
}
.blockOff {
  display: flex;
}
.mainContentContainer > .footer {
  height: 100px;
}
.show {
  display: none;
}
.noShow {
  display: block;
}
.loading-container {
  border-radius: 50px;
  background-color: white;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading-container > div {
  height: 100%;
  display: flex;
  align-items: center;
}
.loading-container > h3 {
  width: 100%;
  text-align: center;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: antraste;
  color: #ffbc22;
  text-shadow: 3px 3px 3px rgb(97, 97, 97, 0.2);
}
.success-container {
  border-radius: 50px;
  background-color: white;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.success-container > h2 {
  padding: 0px 20px;
  color: #ffbc22;
  text-shadow: 3px 3px 3px rgb(97, 97, 97, 0.2);
  font-family: antraste;
}

.success-container > .view-txn {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.success-container > .view-txn > a {
  margin: 20px;
  height: 60px;
  width: 80px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
.success-container > .view-txn > a > span {
  height: 100%;
  width: 100%;
}
.success-container > .view-txn > a > span > img {
  height: 100%;
}
.success-container > .view-txn > a > label {
  text-decoration: none;
  color: black;
}
.success-container > .reset-button {
}

.mint-error {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  padding: 0px;
  font-family: antraste;
  color: rgb(250, 74, 74);
  text-shadow: 3px 3px 3px rgb(97, 97, 97, 0.2);
  text-align: center;
}

.mainContentContainer > .aboutProject {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-family: antraste;
  color: white;
  text-shadow: 3px 3px 5px rgb(97, 97, 97);
  display: flex;
}
.mainContentContainer > .aboutProject > .project,
.mainContentContainer > .aboutProject > .roadmap,
.mainContentContainer > .aboutProject > .team {
  max-width: 1000px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.mainContentContainer > .aboutProject > .project > h1,
.mainContentContainer > .aboutProject > .project > h2 {
  text-align: center;
}
.mainContentContainer > .aboutProject > .project > .video > iframe {
  border: none;
}
.mainContentContainer > .aboutProject > .team > .members {
  padding-top: 10px;
  width: 80%;
}
.mainContentContainer > .aboutProject > .team > .members > .members-container {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.mainContentContainer
  > .aboutProject
  > .team
  > .members
  > .members-container
  > .element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: black;
  text-shadow: none;
}
.mainContentContainer
  > .aboutProject
  > .team
  > .members
  > .members-container
  > .element
  > img {
  height: 150px;
  width: 150px;
  border-radius: 100px;
}
.mainContentContainer
  > .aboutProject
  > .team
  > .members
  > .members-container
  > .element
  > h4 {
  padding: 10px 0px;
  margin: 0;
}
.mainContentContainer
  > .aboutProject
  > .team
  > .members
  > .members-container
  > .element
  > * {
  text-align: center;
}
.mainContentContainer
  > .aboutProject
  > .team
  > .members
  > .members-container
  > .element
  > label {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 14px;
}
.mainContentContainer
  > .aboutProject
  > .team
  > .members
  > .members-container
  > .element
  > a {
  text-decoration: none;
  font-size: 14px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 900;
}

@media screen and (max-width: 800px) {
  .mainContentContainer > .mainContainer {
    flex-direction: column;
  }
  .mainContentContainer > .mainContainer > .cardLeft,
  .mainContentContainer > .mainContainer > .cardRight {
    width: 100%;
    justify-content: center;
  }
  .mainContentContainer > .mainContainer > .cardLeft > ul,
  .mainContentContainer > .mainContainer > .cardRight > ul {
    display: flex;
    flex-direction: row;
  }
  .mainContentContainer > .mainContainer > .cardLeft > ul > li,
  .mainContentContainer > .mainContainer > .cardRight > ul > li {
    height: calc(100vw / 6);
  }
  .mainContentContainer > .mainContainer > .minter > .minterContainer {
    width: 85%;
    max-width: 400px;
  }
  .mainContentContainer > .description > .main {
    padding: 5%;
  }
  .mainContentContainer > .description > .main > .first > span {
  }
  .mainContentContainer > .description > .main > h2 > span {
  }
  .mainContentContainer
    > .aboutProject
    > .team
    > .members
    > .members-container {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  .mainContentContainer
    > .aboutProject
    > .team
    > .members
    > .members-container
    > * {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 500px) {
  .App {
    overflow: hidden;
  }
  .mainContentContainer > .description {
    box-sizing: border-box;
    padding: 0%;
  }
  .mainContentContainer > .description > .main > .first {
    width: 100%;
  }
  .show {
    display: block;
  }
  .noShow {
    display: none;
  }
  .mainContentContainer > .aboutProject > .project {
    width: 95%;
  }
  .mainContentContainer > .aboutProject > .project > .video {
    width: 100%;
  }
  .mainContentContainer > .aboutProject > .project > .video > iframe {
    border: none;
    width: 100%;
  }
}
@media (min-width: 2500px) {
  .App {
    border: solid;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .mainContentContainer {
    width: 2000px;

    box-sizing: border-box;
  }
  .mainContentContainer > .cover {
    height: calc(100vh / 2);
    width: 2000px;
  }
}
.fadeInAnimation {
  -webkit-animation-name: fadeInAnim;
          animation-name: fadeInAnim;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes fadeInAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.roadmapimage,
.holdersgetimage {
  display: flex;
}
.roadmapimage > img,
.holdersgetimage > img {
  max-width: 1000px;
  width: 100%;
}

